import Swal from "sweetalert2";

/**
 * Alert de verificação de exclusão de iten
 */
export async function alertDeletSingleIten() {
  return await Swal.fire({
    icon: 'info',
    text: 'Tem certeza que deseja excluir o item selecionado?',
    showCancelButton: true,
    cancelButtonText: 'Cancelar',
    showCloseButton: true,
    confirmButtonText: '<span class="material-symbols-outlined">delete</span> Excluir',
    buttonsStyling: false,
    customClass: {
      popup: 'swal-table-delete',
      confirmButton: "btn btn-icon btn-theme-danger light-full",
      cancelButton: "btn btn-theme-primary half-full"
    },
  }).then(resp => resp)
}
