<div class="card card-app">
  <div class="card-header">
    <h4 class="card-title">Opções do pedido</h4>
  </div>
  <div class="card-header">
    <button (click)="addGrupo()" class="btn btn-success">Adicionar grupo</button>
  </div>
  <div class="card-body">
    <div class="row mt-2 mb-2" *ngFor="let item of complementos; let index = index">
      <div class="col-12">
        <div class="card card-app">
          <div class="card-header">
            <div class="row">
              <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 d-inline-flex">
                <h6 *ngIf="!editName.checked" class="card-title">{{item.nome || 'complementos'}}</h6>
                <input hidden type="checkbox" #editName>
                <input *ngIf="editName.checked" class="form-control" [(ngModel)]="item.nome">
                <mat-icon (click)="editName.click()">edit</mat-icon>
              </div>
              <div class="col-xl-8 col-lg-8 col-md-6 col-sm-12 d-inline-flex justify-content-end">
                <button (click)="removeComplemento(item, index)" class="btn btn-light fc-red">
                  <i class="bi bi-trash3"></i> Remover
                </button>
              </div>
            </div>
          </div>
          <div class="card-header">
            <div class="row">
              <div class="col-xl-2 col-lg-2 col-md-6 col-sm-12">
                <label class="form-label">Obrigatório</label>
                <select class="form-select form-select-sm" [(ngModel)]="item.obrigatorio">
                  <option [ngValue]="false">Não</option>
                  <option [ngValue]="true">Sim</option>
                </select>
              </div>
              <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                <label class="form-label">Tipo de selação</label>
                <select class="form-select form-select-sm" [(ngModel)]="item.tipoSelecao">
                  <option value="MULTIPLO">Multiplo</option>
                  <option value="UNITARIO">Uma entre essas opções</option>
                </select>
              </div>
              <div class="col-xl-2 col-lg-2 col-md-6 col-sm-12" *ngIf="item.tipoSelecao == 'MULTIPLO'">
                <label class="form-label">Máximo de itens</label>
                <input class="form-control form-control-sm" type="number" value="1" [(ngModel)]="item.limit">
              </div>
            </div>
          </div>
          <div class="card-body">
            <table class="default-table opcao-pedido">
              <thead>
                <tr>
                  <td>Nome</td>
                  <td>Máximo de itens</td>
                  <td>Tipo custo</td>
                  <td>Custo adicional</td>
                  <td>Máximo de extras</td>
                  <td></td>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let itemProduto of item.items; let indexItem = index">
                  <td>
                    <input class="form-control form-control-sm" [(ngModel)]="itemProduto.nome">
                  </td>
                  <td>
                    <input class="form-control form-control-sm" min="1" type="number" [(ngModel)]="itemProduto.limit">
                  </td>
                  <td>
                    <select class="form-select form-select-sm" [(ngModel)]="itemProduto.tipoCusto">
                      <option value="SEM_CUSTO">Sem custo</option>
                      <option value="EXCEDENTE_LIMIT">Excedendo o máximo</option>
                      <option value="ADICIONAL">Adicional</option>
                    </select>
                  </td>
                  <td>
                    <p *ngIf="itemProduto.tipoCusto == 'SEM_CUSTO'" style="text-align: center">--</p>
                    <div *ngIf="itemProduto.tipoCusto !='SEM_CUSTO'" class="input-group">
                      <span class="input-group-text" id="basic-addon1">R$:</span>
                      <input
                        [(ngModel)]="itemProduto.custoAdicional"
                        class="form-control form-control-sm"
                        min="1"
                        input-decimal
                      >
                    </div>
                  </td>
                  <td>
                    <p *ngIf="itemProduto.tipoCusto != 'EXCEDENTE_LIMIT'" style="text-align: center">--</p>
                    <input
                      *ngIf="itemProduto.tipoCusto =='EXCEDENTE_LIMIT'"
                      [(ngModel)]="itemProduto.limitExtras"
                      class="form-control"
                      min="1"
                      type="number"
                    >
                  </td>
                  <td class="td-actions">
                    <div class="default-inline-actions-dg-table">
                      <span (click)="removeItem(item, itemProduto, indexItem)" class="dg-table-action-excluir">
                        <i class="bi bi-trash3"></i>
                      </span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colspan="100%">
                    <div style="width: 100%; height: 100%" class="d-inline-flex justify-content-center">
                      <button (click)="addProdutoItem(index)" class="btn btn-success btn-sm">Adicionar item</button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>


  <div class="card-body">
<!--    <div class="accordion">-->
<!--      <div class="accordion-item" *ngFor="let item of complementos; let index = index">-->
<!--        <h2 class="accordion-header" [attr.id]="'complementos-list-item-id-'+index">-->
<!--          <button-->
<!--            class="accordion-button" type="button" data-bs-toggle="collapse"-->
<!--            [attr.data-bs-target]="'#complementos-list-id-'+index" aria-expanded="true"-->
<!--            [attr.aria-controls]="'complementos-list-id-'+index"-->
<!--          >-->
<!--            {{item.nome || 'complementos'}}-->
<!--          </button>-->
<!--        </h2>-->
<!--        <div-->
<!--          [attr.id]="'complementos-list-id-'+index" class="accordion-collapse collapse show"-->
<!--          [attr.aria-labelledby]="'complementos-list-id-'+index"-->
<!--        >-->
<!--          <div class="accordion-body">-->
<!--            <div class="row">-->
<!--              <div class="col-4">-->
<!--                <div class="input-group mb-3">-->
<!--                  <input class="form-control" [(ngModel)]="item.nome">-->
<!--                  <span class="input-group-text">editar</span>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="col-3 d-inline-flex justify-content-end ms-auto">-->
<!--                <div>-->
<!--                  <button (click)="removeComplemento(item, index)" class="btn btn-danger">Remover</button>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="row mt-3">-->
<!--              <div class="col-3">-->
<!--                <label class="form-label">Obrigatório</label>-->
<!--                <select class="form-select" [(ngModel)]="item.obrigatorio">-->
<!--                  <option [ngValue]="false">Não</option>-->
<!--                  <option [ngValue]="true">Sim</option>-->
<!--                </select>-->
<!--              </div>-->
<!--              <div class="col-3">-->
<!--                <label class="form-label">Tipo de selação</label>-->
<!--                <select class="form-select" [(ngModel)]="item.tipoSelecao">-->
<!--                  <option value="MULTIPLO">Multiplo</option>-->
<!--                  <option value="UNITARIO">Uma entre essas opções</option>-->
<!--                </select>-->
<!--              </div>-->
<!--              <div class="col-3">-->
<!--                <label class="form-label">Máximo de itens</label>-->
<!--                <input class="form-control" type="number" value="1" [(ngModel)]="item.limit">-->
<!--              </div>-->
<!--              <div class="col-3">-->
<!--                <button (click)="addProdutoItem(index)" class="btn btn-success">Adicionar item</button>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="accordion-body">-->
<!--            <ul class="list-group">-->
<!--              <li *ngFor="let itemProduto of item.items; let indexItem = index" class="list-group-item">-->
<!--                <div class="row">-->
<!--                  <div class="col-1 d-inline-flex justify-content-end ms-auto">-->
<!--                    <div>-->
<!--                      <button (click)="removeItem(item, itemProduto, indexItem)" class="btn btn-danger">-</button>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="row">-->
<!--                  <div class="col-3">-->
<!--                    <label class="form-label">Nome</label>-->
<!--                    <input class="form-control" [(ngModel)]="itemProduto.nome">-->
<!--                  </div>-->
<!--                  <div class="col-2">-->
<!--                    <label class="form-label">Máximo de itens</label>-->
<!--                    <input class="form-control" min="1" type="number"-->
<!--                           [(ngModel)]="itemProduto.limit">-->
<!--                  </div>-->
<!--                  <div class="col-2">-->
<!--                    <label class="form-label">Tipo custo</label>-->
<!--                    <select class="form-select" [(ngModel)]="itemProduto.tipoCusto">-->
<!--                      <option value="SEM_CUSTO">Sem custo</option>-->
<!--                      <option value="EXCEDENTE_LIMIT">Excedendo o máximo</option>-->
<!--                      <option value="ADICIONAL">Adicional</option>-->
<!--                    </select>-->
<!--                  </div>-->
<!--                  <div class="col-2" *ngIf="itemProduto.tipoCusto !='SEM_CUSTO'">-->
<!--                    <label class="form-label">Custo adicional</label>-->
<!--                    <input class="form-control" min="1" type="number"-->
<!--                           [(ngModel)]="itemProduto.custoAdicional">-->
<!--                  </div>-->
<!--                  <div class="col-2" *ngIf="itemProduto.tipoCusto =='EXCEDENTE_LIMIT'">-->
<!--                    <label class="form-label">Máximo de extras</label>-->
<!--                    <input class="form-control" min="1" type="number"-->
<!--                           [(ngModel)]="itemProduto.limitExtras">-->
<!--                  </div>-->
<!--                </div>-->
<!--              </li>-->
<!--            </ul>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
  </div>

</div>
