<div class="custom-container">
  <div class="row">
    <div class="col-md-6">
      <div class="public-home-text">
        <h1>Bem-vindo a <span style="text-decoration: line-through red;">Sisgend</span></h1>
        <p>
          Esta é uma página simples criada apresentação do
          <span style="text-decoration: line-through red;">Sisgend</span>.
          <br>
          Abaixo estão as empresas cadastradas
        </p>
      </div>
    </div>
    <div class="col-md-6">
      <img src="assets/images/construcao-1000x750.png" alt="Imagem de Fundo" class="img-fluid img-construcao">
    </div>
  </div>

  <div class="company-cards mt-5">
    <div class="row justify-content-center">
      <div *ngFor="let item of estabelecimentos" class="col-md-2">
        <a [href]="replaceEndpointParams(item?.link || '')" target="_blank">
          <div class="card">
            <img [src]="item?.logoImgUrl || 'https://via.placeholder.com/600'" class="card-img-top" alt="Logo da Empresa">
            <div class="card-body">
              <h5 class="card-title">{{ item?.nome }}</h5>
            </div>
          </div>
        </a>
      </div>
    </div>
  </div>
</div>
