import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProdutosMainComponent } from './produtos-main/produtos-main.component';
import {CdkDynamicTableModule, DgPaginatorModule, DgTableModule} from "@datagrupo/dg-ng-util";
import {RouterModule} from "@angular/router";
import {FoodProdutosInsertEditComponent} from "./food-produtos-insert-edit/food-produtos-insert-edit.component";
import {FichaProdutoComponent} from "./sub-components/ficha-produto/ficha-produto.component";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {DgCrudModule} from "@datagrupo/dg-crud";
import {DirectivesModule} from "../../../../directives/directives.module";
import {MatIconModule} from "@angular/material/icon";



@NgModule({
  declarations: [
    ProdutosMainComponent,
    FoodProdutosInsertEditComponent,
    FichaProdutoComponent
  ],
  imports: [
    CommonModule,
    DgPaginatorModule,
    DgTableModule,
    CdkDynamicTableModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    DgCrudModule,
    DirectivesModule,
    MatIconModule
  ]
})
export class ProdutoModule { }
