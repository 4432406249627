import {Component, Input, OnInit} from '@angular/core';
import {SessionService} from "../../../../../../services/session/session.service";
import {HttpService} from "../../../../../../services/http-service/http.service";
import {
  FOOD_PRODUTO_COMPLEMENTOS,
  FOOD_PRODUTO_COMPLEMENTOS_ITEM
} from "../../../../../../core/endpoints";
import {FoodProdutoComplementosEntity} from "../../../../../../entitys/food-produto-complementos.entity";
import {FoodProdutoComplementosItemsEntity} from "../../../../../../entitys/food-produto-complementos-items.entity";
import Swal from "sweetalert2";

@Component({
  selector: 'ficha-produto',
  templateUrl: './ficha-produto.component.html',
  styleUrls: ['./ficha-produto.component.scss']
})
export class FichaProdutoComponent implements OnInit {

  @Input('complementos') complementos: FoodProdutoComplementosEntity[] = []

  constructor(
    private session: SessionService,
    private service: HttpService
  ) { }

  ngOnInit(): void {
  }

  addGrupo() {
    this.complementos.push(new FoodProdutoComplementosEntity(
      undefined, '', false,'','',[], true
    ))
  }

  addProdutoItem(index: number) {
    if (!this.complementos[index]) return;

    this.complementos[index].items.push(new FoodProdutoComplementosItemsEntity(
      undefined,'','', '', '',''
      , {id: this.session.user.estabalecimento}
    ))
  }

  removeComplemento(complemento: FoodProdutoComplementosEntity, index: number) {
    Swal.fire({
      icon: 'warning',
      title: 'Remover lista completa de seleção',
      showCancelButton: true,
      cancelButtonText: 'Sair'
    }).then(conf => {
      if (conf.isConfirmed) {
        if (complemento.id) {
          this.service.delete(FOOD_PRODUTO_COMPLEMENTOS+'/'+complemento.id).subscribe(resp => {
            this.complementos.splice(index, 1);
          })
        } else {
          this.complementos.splice(index, 1);
        }
      }
    })
  }

  removeItem(complemento: FoodProdutoComplementosEntity, item: FoodProdutoComplementosItemsEntity, indexItem: number) {
    Swal.fire({
      icon: 'warning',
      title: 'Remover item da lista',
      showCancelButton: true,
      cancelButtonText: 'Sair'
    }).then(conf => {
      if (conf.isConfirmed) {
        if (item.id) {
          this.service.delete(FOOD_PRODUTO_COMPLEMENTOS_ITEM+'/'+item.id).subscribe(resp => {
            complemento.items.splice(indexItem, 1);
          })
        } else {
          complemento.items.splice(indexItem, 1);
        }
      }
    })
  }
}
