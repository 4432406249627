import {EstabelecimentoEntity} from "../pages/private/estabelecimento/estabelecimento.entity";


export class FoodProdutoComplementosItemsEntity {
  constructor(
    id?: number | string,
    nome?: string,
    limit?: string | number,
    limitExtras?: string | number,
    tipoCusto?: string,
    custoAdicional?: string,
    estabelecimento?: Partial<EstabelecimentoEntity>,
  ) {
    this.id = id;
    this.nome = nome;
    this.limit = limit;
    this.limitExtras = limitExtras;
    this.tipoCusto = tipoCusto;
    this.custoAdicional = custoAdicional;
    this.estabelecimento = estabelecimento;
  }

  id: number | string | undefined;
  nome: string | undefined;
  limit: string | number | undefined;
  limitExtras: string | number | undefined;
  tipoCusto: string | undefined;
  custoAdicional: string | undefined;
  estabelecimento: Partial<EstabelecimentoEntity> | undefined;
}
