<nav id="private-header" class="navbar navbar-expand-lg">
  <div class="container-fluid">
    <a class="navbar-brand" routerLink="/private/home">Sisgend</a>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown-cadastros" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Cadastros
          </a>
          <ul class="dropdown-menu" aria-labelledby="navbarDropdown-cadastros">
            <li>
              <a routerLink="/private/cadastros/produtos" class="dropdown-item" href="#">Produtos</a>
            </li>
            <li>
              <a routerLink="/private/cadastros/categorias" class="dropdown-item" href="#">Categorias</a>
            </li>
            <li>
              <a routerLink="/private/cadastros/clientes" class="dropdown-item" href="#">Clientes</a>
            </li>
            <li>
              <a routerLink="/private/cadastros/colaboradores" class="dropdown-item" href="#">Usuários</a>
            </li>
            <li>
              <a routerLink="/private/cadastros/entregador" class="dropdown-item" href="#">Entregador</a>
            </li>
            <li style="display: block">
              <hr class="dropdown-divider">
            </li>
            <li>
              <a routerLink="/private/cadastros/empresa/{{session.user.estabalecimento}}" class="dropdown-item" href="#">Dados da empresa</a>
            </li>
            <li>
              <a
                href="http://localhost:4300/client/{{session.user.linkEstabelecimento}}/start-editor-mode/{{session.token.getToken()}}"
                target="_blank"
                class="dropdown-item"
              >Editar Template site</a>
            </li>
          </ul>
        </li>

        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown-relatorios" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Relatórios
          </a>
          <ul class="dropdown-menu" aria-labelledby="navbarDropdown-relatorios">
            <li>
              <span routerLink="/private/cadastros/produtos" class="dropdown-item" href="#">Vendas</span>
            </li>
          </ul>
        </li>

        <li class="nav-item">
          <a class="nav-link" aria-current="page"routerLink="/private/food/esteira-pedidos">Esteira de vendas</a>
        </li>
      </ul>

      <ul class="navbar-nav ms-auto mb-2 mb-lg-0 h-100">
        <li class="nav-item">
          <span class="nav-link">
            <mat-icon class="icon-center">receipt_long</mat-icon>
            <span *ngIf="!!numeroPedidosEmAnalise" class="badge bg-primary rounded-pill">
              {{numeroPedidosEmAnalise}}
            </span>
          </span>
        </li>
        <li class="nav-item dropdown">
          <span
            class="nav-link dropdown-toggle"
            href="#"
            id="navbarDropdown"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <mat-icon class="icon-center">account_circle</mat-icon>
          </span>
          <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
            <li><span class="dropdown-item">Dados da empresa</span></li>
            <li><span class="dropdown-item">Meu perfil</span></li>
            <li><hr class="dropdown-divider"></li>
            <li>
              <mat-icon class="icon-center">logout</mat-icon>
              <span class="dropdown-item" (click)="session.logout()">
              Sair
            </span>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</nav>

<router-outlet>
</router-outlet>
