<dg-modal
  #modal
  title="Montar entrega"
  [size]="!form.value?.entregador ? 'sm' : 'lg'"
  (afterClose)="clear()"
>
  <div modal-body class="modal-esteira-entrega">
    <form [formGroup]="form">
      <div class="row">
        <div class="col-12">
          <label class="form-label">Entregador</label>
          <select formControlName="entregador" class="form-select">
            <option value="" disabled>Selecione um entregador</option>
            <option
              *ngFor="let item of listEntregadores"
              [value]="item.id"
            >
              {{item.nome}}
            </option>
          </select>
        </div>
      </div>
    </form>

    <div class="row mt-3" style="height: 20rem" *ngIf="!!form.value?.entregador">
      <div class="col-5" style="height: 100%;">
        <div class="card card-content-status-pedido-esteira" style="height: 100%;">
          <div class="card-header">
            <h6 class="card-title">Pronto</h6>
          </div>
          <div
            class="card-body"
            cdkDropList
            id="list-pedidosProntos"
            #pedidosProntos="cdkDropList"
            [cdkDropListData]="listPedidosProntos"
            [cdkDropListConnectedTo]="[pedidosSelecionados]"
            (cdkDropListDropped)="drop($event)"
          >
            <div class="card col-12" *ngFor="let pedido of listPedidosProntos; let index = index" cdkDrag>
              <div class="card-header d-inline-flex justify-content-between">
                <div class="example-custom-placeholder" *cdkDragPlaceholder></div>

                <div class="row w-100 h-100 pointer">
                  <div class="col-1" (click)="selectInputDisponivel.click()">
                    <input type="checkbox" [checked]="selectInputDisponivel.checked">
                    <input #selectInputDisponivel hidden type="checkbox" [(ngModel)]="pedido.select">
                  </div>
                  <div class="col-10">
                    <h6 class="card-title">{{pedido.venda?.cliente?.nome || '--'}}</h6>
                  </div>
                </div>

                <div *cdkDragPreview class="custom-preview-entrega">
                  <div>
                    <h6 class="card-title">{{pedido.venda?.cliente?.nome || '--'}}</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-2 column-buttons">
        <button class="btn btn-sm btn-light" (click)="voltarSelecionados()">
          <mat-icon>arrow_back_ios</mat-icon>
          Voltar selecionados
        </button>
        <button class="btn btn-sm btn-light" (click)="passarSelecionados()">
          Passar selecionados
          <mat-icon>arrow_forward_ios</mat-icon>
        </button>
        <button class="btn btn-sm btn-light" (click)="voltarTodos()">
          <mat-icon style="transform: rotate(180deg)">double_arrow</mat-icon>
          Voltar todos
        </button>
        <button class="btn btn-sm btn-light" (click)="passarTodos()">
          Passar todos
          <mat-icon>double_arrow</mat-icon>
        </button>
      </div>
      <div class="col-5" style="height: 100%;">
        <div class="card card-content-status-pedido-esteira" style="height: 100%;">
          <div class="card-header">
            <h6 class="card-title">Pronto</h6>
          </div>
          <div
            class="card-body"
            cdkDropList
            id="list-pedidosSelecionados"
            #pedidosSelecionados="cdkDropList"
            [cdkDropListData]="listPedidosSelecionados"
            [cdkDropListConnectedTo]="[pedidosProntos]"
            (cdkDropListDropped)="drop($event)"
          >
            <div class="card col-12" *ngFor="let pedido of listPedidosSelecionados; let index = index" cdkDrag>
              <div class="card-header d-inline-flex justify-content-between">
                <div class="example-custom-placeholder" *cdkDragPlaceholder></div>

                <div class="row w-100 h-100 pointer">
                  <div class="col-1" (click)="selectInputDisponivel.click()">
                    <input type="checkbox" [checked]="selectInputDisponivel.checked">
                    <input #selectInputDisponivel hidden type="checkbox" [(ngModel)]="pedido.select">
                  </div>
                  <div class="col-10">
                    <h6 class="card-title">{{pedido.venda?.cliente?.nome || '--'}}</h6>
                  </div>
                </div>

                <div *cdkDragPreview class="custom-preview-entrega">
                  <div>
                    <h6 class="card-title">{{pedido.venda?.cliente?.nome || '--'}}</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
  <div modal-footer>
    <button (click)="close()" class="btn btn-secondary m-1">Sair</button>
    <button (click)="save()" class="btn btn-success m-1">Salvar</button>
  </div>
</dg-modal>
