import { Component, OnInit } from '@angular/core';
import {HttpService} from "../../../services/http-service/http.service";
import {EstabelecimentoEntity} from "../../private/estabelecimento/estabelecimento.entity";
import {replaceEndpointParams} from "../../../helpers/endpoints-helpers";
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'app-public-home',
  templateUrl: './public-home.component.html',
  styleUrls: ['./public-home.component.scss']
})
export class PublicHomeComponent implements OnInit {

  estabelecimentos: EstabelecimentoEntity[] = [];

  replaceEndpointParams = (val: string) => replaceEndpointParams(environment.domainTemplateUrl, val);

  constructor(private service: HttpService) {
    service.get('empresa/empresas').subscribe(
      resp => {
        this.estabelecimentos = resp.data;
      }
    )
  }

  ngOnInit(): void {
  }

}
