import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FoodEsteiraPedidosModule} from "./food-esteira-pedidos/food-esteira-pedidos.module";



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    FoodEsteiraPedidosModule
  ]
})
export class FoodModule { }
