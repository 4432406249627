import {AbstractEntity2, CrudEntity} from "@datagrupo/dg-crud";
import {DynamicTableEntity, DynamicColumn} from "@datagrupo/dg-ng-util";
import {environment} from "../../../../../environments/environment";
import {COLABORADOR} from "../../../../core/endpoints";
import {EstabelecimentoEntity} from "../../estabelecimento/estabelecimento.entity";

@CrudEntity({
  api: {
    path: environment.apiUrl,
    context: COLABORADOR
  }
})
@DynamicTableEntity({
  api: {
    path: environment.apiUrl,
    context: COLABORADOR
  }
})
export class ColaboradorEntity extends AbstractEntity2 {

  constructor(
    nome?: string,
    cargo?: string,
    telefone?: string,
    email?: string,
    estabelecimento?: Partial<EstabelecimentoEntity>,
  ) {
    super();
    this.nome = nome;
    this.cargo = cargo;
    this.telefone = telefone;
    this.email = email;
    this.estabelecimento = estabelecimento;
  }

  @DynamicColumn({headerName: 'Nome'})
  nome: string | undefined;
  cargo: string | undefined;
  telefone: string | undefined;
  email: string | undefined;
  estabelecimento: Partial<EstabelecimentoEntity> | undefined;
}
