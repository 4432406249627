import {AbstractEntity2, CrudEntity} from "@datagrupo/dg-crud";
import {environment} from "../../../../environments/environment";
import {ESTABELECIMENTO} from "../../../core/endpoints";
import {DiasTrabalhadosEntity} from "../../../shared/entitys/dias-trabalhados.entity";
import {DynamicColumn, DynamicTableEntity} from "@datagrupo/dg-ng-util";


@CrudEntity({
  api: {
    path: environment.apiUrl,
    context: ESTABELECIMENTO
  }
})
@DynamicTableEntity({
  api: {
    path: environment.apiUrl,
    context: ESTABELECIMENTO
  }
})
export class EstabelecimentoEntity extends AbstractEntity2 {

  constructor(
    nome?: string,
    telefone?: string,
    endereco?: string,
    instagram?: string,
    facebook?: string,
    rua?: string,
    logradouro?: string,
    numero?: string,
    complemento?: string,
    bairro?: string,
    cidade?: string,
    estado?: string,
    link?: string,
    logoImg?: string,
    logoImgUrl?: string,
    descricao?: string,


    diasTrabalhadosSemena?: DiasTrabalhadosEntity,
    domingoStatus?: boolean,
    domingoAbertura?: string,
    domingoFechamento?: string,
    segundaStatus?: boolean,
    segundaAbertura?: string,
    segundaFechamento?: string,
    tercaStatus?: boolean,
    tercaAbertura?: string,
    tercaFechamento?: string,
    quartaStatus?: boolean,
    quartaAbertura?: string,
    quartaFechamento?: string,
    quintaStatus?: boolean,
    quintaAbertura?: string,
    quintaFechamento?: string,
    sextaStatus?: boolean,
    sextaAbertura?: string,
    sextaFechamento?: string,
    sabadoStatus?: boolean,
    sabadoAbertura?: string,
    sabadoFechamento?: string,
  ) {
    super();
    this.nome = nome;
    this.telefone = telefone;
    this.instagram = instagram;
    this.facebook = facebook;
    this.rua = rua;
    this.logradouro = logradouro;
    this.numero = numero;
    this.complemento = complemento;
    this.bairro = bairro;
    this.cidade = cidade;
    this.estado = estado;
    this.link = link;
    this.logoImg = logoImg;
    this.logoImgUrl = logoImgUrl;
    this.descricao = descricao;
    this.diasTrabalhadosSemena = !!diasTrabalhadosSemena ? diasTrabalhadosSemena : new DiasTrabalhadosEntity()

    this.domingoStatus = domingoStatus;
    this.domingoAbertura = domingoAbertura;
    this.domingoFechamento = domingoFechamento;

    this.segundaStatus = segundaStatus;
    this.segundaAbertura = segundaAbertura;
    this.segundaFechamento = segundaFechamento;

    this.tercaStatus = tercaStatus;
    this.tercaAbertura = tercaAbertura;
    this.tercaFechamento = tercaFechamento;

    this.quartaStatus = quartaStatus;
    this.quartaAbertura = quartaAbertura;
    this.quartaFechamento = quartaFechamento;

    this.quintaStatus = quintaStatus;
    this.quintaAbertura = quintaAbertura;
    this.quintaFechamento = quintaFechamento;

    this.sextaStatus = sextaStatus;
    this.sextaAbertura = sextaAbertura;
    this.sextaFechamento = sextaFechamento;

    this.sabadoStatus = sabadoStatus;
    this.sabadoAbertura = sabadoAbertura;
    this.sabadoFechamento = sabadoFechamento;
  }

  @DynamicColumn({headerName: 'Nome'})
  nome: string | undefined;
  @DynamicColumn({headerName: 'Telefone'})
  telefone: string | undefined;
  @DynamicColumn({headerName: 'Instagram'})
  instagram: string | undefined;
  @DynamicColumn({headerName: 'Facebook'})
  facebook: string | undefined;

  rua: string | undefined;
  logradouro: string | undefined;
  numero: string | undefined;
  complemento: string | undefined;
  bairro: string | undefined;
  cidade: string | undefined;
  estado: string | undefined;
  link: string | undefined;
  logoImg: string | undefined;
  logoImgUrl: string | undefined;
  descricao: string | undefined;


  diasTrabalhadosSemena: DiasTrabalhadosEntity;

  domingoStatus: boolean | undefined;
  domingoAbertura: string | undefined;
  domingoFechamento: string | undefined;

  segundaStatus: boolean | undefined;
  segundaAbertura: string | undefined;
  segundaFechamento: string | undefined;

  tercaStatus: boolean | undefined;
  tercaAbertura: string | undefined;
  tercaFechamento: string | undefined;

  quartaStatus: boolean | undefined;
  quartaAbertura: string | undefined;
  quartaFechamento: string | undefined;

  quintaStatus: boolean | undefined;
  quintaAbertura: string | undefined;
  quintaFechamento: string | undefined;

  sextaStatus: boolean | undefined;
  sextaAbertura: string | undefined;
  sextaFechamento: string | undefined;

  sabadoStatus: boolean | undefined;
  sabadoAbertura: string | undefined;
  sabadoFechamento: string | undefined;

}
