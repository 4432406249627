<div class="card card-pedido-esteira" [class.selected]="!!this.data['selectedItem']" (click)="_clickSelect($event)">
  <div
    class="card-header"
    cdkDrag
  >
    <div class="row">
      <div class="col-12 d-inline-flex justify-content-between">
        <span>{{data?.cliente?.nome || '--'}}</span>
        <span>N° 1235</span>
      </div>
    </div>
  </div>

  <div class="card-body d-inline-flex justify-content-between">
    <table class="w-100">
      <tbody>
        <tr>
          <td>Total de itens:</td>
          <td>{{data.produtos.length}}</td>
        </tr>
        <tr>
          <td>Total</td>
          <td>R$ {{data?.total}}</td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="card-footer d-inline-flex justify-content-between">
    <button [disabled]="data?.selectedItem" (click)="modalPedido.open(data)" class="btn btn-info btn-sm">Abrir</button>
    <button [disabled]="data?.selectedItem" *ngIf="data?.status == 'EM_ANALISE'" (click)="changeStatus(data, 'PRODUCAO')" class="btn btn-success btn-sm">Aceitar</button>
    <button [disabled]="data?.selectedItem" *ngIf="data?.status == 'PRODUCAO'" (click)="changeStatus(data, 'PRONTO')" class="btn btn-success btn-sm">Pronto</button>
<!--    <button [disabled]="data?.selectedItem" *ngIf="data?.status == 'PRONTO'" (click)="changeStatus(data, 'CAMINHO')" class="btn btn-success btn-sm">A caminho</button>-->
    <button [disabled]="data?.selectedItem" *ngIf="data?.status == 'CAMINHO'" (click)="changeStatus(data, 'ENTREGUE')" class="btn btn-success btn-sm">Finalizado</button>
  </div>
</div>


<modal-esteira-pedido #modalPedido>
</modal-esteira-pedido>
