import { Component, OnInit } from '@angular/core';
import {CdkDynamicTable, CdkDynamicTableService} from "@datagrupo/dg-ng-util";
import {ActivatedRoute, Router} from "@angular/router";
import {EmpresaEntity} from "../empresa.entity";
import {HttpService} from "../../../../../services/http-service/http.service";
import {ESTABELECIMENTO} from "../../../../../core/endpoints";

@Component({
  selector: 'app-empresa-main',
  templateUrl: './empresa-main.component.html',
  styleUrls: ['./empresa-main.component.scss']
})
export class EmpresaMainComponent implements OnInit {

  public table: CdkDynamicTable.tableClass;

  constructor(
    private dynamicTable: CdkDynamicTableService,
    private router: Router,
    private activate: ActivatedRoute,
    private http: HttpService
  ) {
    this.table = dynamicTable.createByEntity(new EmpresaEntity())
    this.table.controls.actions.setObject({
      editar: {
        name: 'Editar',
        action: (val: any) => router.navigate([val.id], { relativeTo: activate }),
        icon: 'bi bi-pencil-fill'
      },
      excluir: {
        name: 'Excluir',
        action: (val: any) => {
          http.deleteById(ESTABELECIMENTO, val.id).subscribe(resp => {
            this.table.find()
          })
        },
        icon: 'bi bi-trash3'
      }
    })
  }

  ngOnInit(): void {
  }

}
