import {Component, OnInit, ViewChild} from '@angular/core';
import {CdkDynamicTable, CdkDynamicTableService} from "@datagrupo/dg-ng-util";
import {EntregadorEntity} from "../entregador.entity";
import {
  ModalEntregadorInsertEditComponent
} from "../modal-entregador-insert-edit/modal-entregador-insert-edit.component";
import {HttpService} from "../../../../../services/http-service/http.service";

@Component({
  selector: 'app-entregador-main',
  templateUrl: './entregador-main.component.html',
  styleUrls: ['./entregador-main.component.scss']
})
export class EntregadorMainComponent implements OnInit {

  @ViewChild('modal') modal!: ModalEntregadorInsertEditComponent;

  table: CdkDynamicTable.tableClass;

  constructor(private createTable: CdkDynamicTableService, private http: HttpService) {
    this.table = createTable.createByEntity(new EntregadorEntity(), {
      actions: {
        add: {
          editar: {
            name: 'Editar',
            action: (val: any) => this.modal.open(val),
            icon: 'bi bi-pencil-fill'
          },
          // excluir: {
          //   name: 'Excluir',
          //   action: (val: any) => {
          //     http.deleteById(ENTREGADOR, val.id).subscribe(resp => {
          //       this.table.find()
          //     })
          //   },
          //   icon: 'bi bi-trash3'
          // }
        }
      }
    })
  }

  ngOnInit(): void {
  }

}
