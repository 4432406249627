import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CategoriaMainComponent} from "./categoria-main/categoria-main.component";
import {CdkDynamicTableModule, DgModalModule, DgPaginatorModule, DgTableModule} from "@datagrupo/dg-ng-util";
import {ReactiveFormsModule} from "@angular/forms";
import {DgCrudModule} from "@datagrupo/dg-crud";
import {RouterModule} from "@angular/router";
import {
  ModalCategoriaInsertEditComponent
} from "./sub-components/modal-categoria-insert-edit/modal-categoria-insert-edit.component";


@NgModule({
  declarations: [
    CategoriaMainComponent,
    ModalCategoriaInsertEditComponent,
  ],
  imports: [
    CommonModule,
    DgTableModule,
    CdkDynamicTableModule,
    DgPaginatorModule,
    ReactiveFormsModule,
    DgCrudModule,
    RouterModule,
    DgModalModule
  ]
})
export class CategoriaModule {
}
