<input
  #inputFile hidden type="file" [(ngModel)]="file" (change)="uploadFile($event)"
  accept="image/*"
>
<div class="container mt-4">
  <div class="row">
    <div class="col-12">
      <div class="card card-app">
        <div class="card-header">
          <h4 class="card-title">Produto</h4>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-xl-3">
              <div class="row">
                <div class="col-12">
                  <img class="rounded" style="max-width: 100%" [src]="entity?.imageUrl || 'assets/images/default/default-placeholder.png'" alt="Imagem do produto">
                </div>
              </div>
              <div class="row">
                <div class="col-12 d-inline-flex justify-content-center">
                  <button (click)="inputFile.click()" class="btn btn-sm btn-success m-1">Alterar</button>
                  <button class="btn btn-sm btn-danger m-1">Remover</button>
                </div>
              </div>
            </div>
            <div class="col-xl-9">
              <form [formGroup]="form">
                <div class="row">
                  <div class="col-4">
                    <label class="form-label">nome</label>
                    <input class="form-control" formControlName="nome">
                  </div>
                  <div class="col-4">
                    <label class="form-label">categorias</label>
                    <select formControlName="categoria" class="form-select">
                      <option value="" disabled>Selecionar categoria</option>
                      <option *ngFor="let item of listCategorias" [value]="item.id">{{item.nome}}</option>
                    </select>
                  </div>
                  <div class="col-4">
                    <label class="form-label">valor</label>
                    <div class="input-group mb-3">
                      <span class="input-group-text" id="basic-addon1">R$:</span>
                      <input class="form-control" input-decimal formControlName="valor">
                    </div>
                  </div>
                </div>

                <div class="row">
                  <label class="form-label">Descrição</label>
                  <div class="col-12">
                    <textarea formControlName="descricao" class="form-control"></textarea>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="card-body">
<!--          <ul class="nav nav-tabs">-->
<!--            <li class="nav-item">-->
<!--              <a class="nav-link active" aria-current="page" href="#">Opções de seleção</a>-->
<!--            </li>-->
<!--            <li class="nav-item">-->
<!--              <a class="nav-link" href="#">Insumos</a>-->
<!--            </li>-->
<!--            <li class="nav-item">-->
<!--              <a class="nav-link" href="#">CMV</a>-->
<!--            </li>-->
<!--          </ul>-->
<!--          <ficha-produto [complementos]="complementos"></ficha-produto>-->
        </div>
        <div class="card-footer">
          <button crud-salvar class="btn btn-success">Salvar</button>
        </div>
      </div>
    </div>
  </div>

  <div class="row mb-3 mt-3">
    <div class="col-12">
      <ul class="nav nav-tabs">
        <li class="nav-item">
          <span class="nav-link active" aria-current="page">Opções do pedido</span>
        </li>
        <li class="nav-item">
          <span class="nav-link">Ficha técnica</span>
        </li>
        <li class="nav-item">
          <span class="nav-link">Sugerir Acompanhamento</span>
        </li>
      </ul>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <ficha-produto [complementos]="complementos"></ficha-produto>
    </div>
  </div>

</div>
