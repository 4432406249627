<div class="container mt-4">
  <div class="row">
    <div class="col-12">
      <div class="card card-app">
        <div class="card-header">
          <h4 class="card-title">Produtos</h4>
        </div>
        <div class="card-header">
          <div class="row">
            <div class="col-3">
              <label class="form-label">Nome</label>
              <input class="form-control">
            </div>
            <div class="col-3">
              <label class="form-label">Valor</label>
              <input class="form-control">
            </div>
            <div class="col-3">
              <label class="form-label">Categoria</label>
              <input class="form-control">
            </div>
          </div>
        </div>
        <div class="card-body">
          <dg-table class="default-table" dg-dynamic-table [dynamicTable]="table"></dg-table>
        </div>
        <div class="card-footer d-inline-flex justify-content-center">
          <dg-paginator dg-dynamic-paginator [dynamicTable]="table"></dg-paginator>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="page-footer">
  <button routerLink="novo" class="btn btn-success">Novo</button>
</div>
