import { Component, OnInit } from '@angular/core';
import {HttpService} from "../../../../services/http-service/http.service";
import {CATEGORIA, COLABORADOR, SERVICOS_AGENDAVEIS} from "../../../../core/endpoints";
import {ServicosAgendaveisEntity} from "../../../private/servicos-agendaveis/servicos-agendaveis.entity";
// import {CategoriaEntity} from "../../../private/categoria/categoria.entity";
import Swal from "sweetalert2";
import {ColaboradorEntity} from "../../../private/cadastros/colaborador/colaborador.entity";

@Component({
  selector: 'app-site-agendamento-home',
  templateUrl: './site-agendamento-home.component.html',
  styleUrls: ['./site-agendamento-home.component.scss']
})
export class SiteAgendamentoHomeComponent implements OnInit {

  listaServicos: ServicosAgendaveisEntity[] = [];
  listaCategorias: /*CategoriaEntity*/any[] = [];
  listaColaboradores: ColaboradorEntity[] = [];
  horasDisponiveis = ['10:15', '10:30', '10:45', '11:00', '11:15', '11:30' ]

  listaServicosSelecionados: ServicosAgendaveisEntity[] = [];
  colaboradorSelecionado: ColaboradorEntity | undefined;

  statusPage: 'selecionar-servico' | 'selecionar-colaborador' | 'agendar' = 'selecionar-servico';

  constructor(private http: HttpService) {

    http.get(SERVICOS_AGENDAVEIS).subscribe(resp => {
      this.listaServicos = resp.data
    })
    http.get(CATEGORIA).subscribe(resp => {
      this.listaCategorias = resp.data
    })
    http.get(COLABORADOR).subscribe(resp => {
      this.listaColaboradores = resp.data
    })

  }

  ngOnInit(): void {
  }

  selectService(value: ServicosAgendaveisEntity){
    const index = this.listaServicosSelecionados.findIndex( item => item.id == value.id)

    if (index == -1) {
      this.listaServicosSelecionados.push(value)
    } else {
      this.listaServicosSelecionados.splice(index, 1)
    }
  }

  selectedService(value: ServicosAgendaveisEntity){
    return this.listaServicosSelecionados.findIndex( item => item.id == value.id) != -1
  }

  calcSubTotal() {
    let result: number = 0;
    this.listaServicosSelecionados.map(item => result = result + Number(item.valor))
    return result
  }

  calcDesconto() {
    return '0,00';
  }

  calcTotal() {
    let result: number = 0;
    this.listaServicosSelecionados.map(item => result = result + Number(item.valor))
    return result
  }

  selecionarColaborador(value: ColaboradorEntity) {
    this.colaboradorSelecionado = value;
    this.statusPage = 'agendar'
  }

  agendar(hora: string) {
    const data = {
      servicos: this.listaServicosSelecionados,
      colaborador: this.colaboradorSelecionado,
      startService: hora
    }
    this.http.post('agendamento', data).subscribe(resp => {
      this.listaServicosSelecionados = [];
      this.colaboradorSelecionado = undefined;
      this.statusPage = "selecionar-servico";

      Swal.fire({
        icon: 'success',
        title: 'Agendamento realizado',
        text: 'Aguardamos você'
      }).then()
    })
  }

  backStatusPage() {
    if (this.statusPage == 'selecionar-colaborador') {
      return this.statusPage = 'selecionar-servico'
    }
    return this.statusPage = 'selecionar-servico'
  }
}
