import {AbstractEntity2} from "@datagrupo/dg-crud";
import {DynamicColumn, DynamicTableEntity} from "@datagrupo/dg-ng-util";
import {ENTREGADOR} from "../../../../core/endpoints";
import {environment} from "../../../../../environments/environment";

@DynamicTableEntity({
  api: {
    path: environment.apiUrl,
    context: ENTREGADOR
  },
})
export class EntregadorEntity extends AbstractEntity2 {
  constructor(
    id?: string,
    nome?: string,
    placa?: string,
  ) {
    super()
    this.id = id;
    this.nome = nome;
    this.placa = placa;
  }

  @DynamicColumn({ name: 'Nome' })
  nome: string | undefined;
  @DynamicColumn({ name: 'Placa' })
  placa: string | undefined;
}
