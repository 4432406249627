import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {RouterModule} from "@angular/router";
import {HomeModule} from "./home/home.module";
import {EstabelecimentoModule} from "./estabelecimento/estabelecimento.module";
import {ServicosAgendaveisModule} from "./servicos-agendaveis/servicos-agendaveis.module";
import {HTTP_INTERCEPTORS} from "@angular/common/http";
import {AddTokenInterceptor} from "../../core/interceptors/add-token/add-token.interceptor";
import {FoodModule} from "./food/food.module";
import {FormaPagamentoModule} from "./cadastros/forma-pagamento/forma-pagamento.module";
import {EmpresaModule} from "./cadastros/empresa/empresa.module";
import {ClientesModule} from "./cadastros/clientes/clientes.module";
import {ColaboradorModule} from "./cadastros/colaborador/colaborador.module";
import {EntregadorModule} from "./cadastros/entregador/entregador.module";
import {CategoriaModule} from "./cadastros/categoria/categoria.module";
import {ProdutoModule} from "./cadastros/produto/produto.module";



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule,
    HomeModule,
    EstabelecimentoModule,
    ColaboradorModule,
    CategoriaModule,
    ServicosAgendaveisModule,
    ClientesModule,
    FoodModule,
    EmpresaModule,
    EntregadorModule,
    FormaPagamentoModule,
    ProdutoModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AddTokenInterceptor, multi: true },
    // { provide: HTTP_INTERCEPTORS, useClass: CallbackMessageInterceptor, multi: true },
  ]
})
export class PrivatePagesModule { }
