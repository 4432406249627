import {Component, OnInit, ViewChild} from '@angular/core';
import {
  CdkDynamicTable,
  CdkDynamicTableService
} from "@datagrupo/dg-ng-util";
import {ActivatedRoute, Router} from "@angular/router";
import {HttpService} from "../../../../../services/http-service/http.service";
import {CATEGORIA} from "../../../../../core/endpoints";
import {FoodCategoriaEntity} from "../../../../../entitys/food-categoria.entity";
import {
  ModalCategoriaInsertEditComponent
} from "../sub-components/modal-categoria-insert-edit/modal-categoria-insert-edit.component";
import {defaultActions} from "../../../../../core/config/dg-ng-util/config-local-dg-table";

@Component({
  selector: 'app-categoria-main',
  templateUrl: './categoria-main.component.html',
  styleUrls: ['./categoria-main.component.scss']
})
export class CategoriaMainComponent implements OnInit {

  @ViewChild('modal') modal!: ModalCategoriaInsertEditComponent;

  public table: CdkDynamicTable.tableClass;

  constructor(
    private dynamicTable: CdkDynamicTableService,
    private router: Router,
    private activate: ActivatedRoute,
    private http: HttpService
  ) {
    this.table = dynamicTable.createByEntity(new FoodCategoriaEntity())
    this.table.controls.actions.setObject({
      editar: {
        name: 'Editar',
        action: (val: any) => {
          this.modal.open(val);
        },
        icon: 'bi bi-pencil-fill'
      },
      excluir: {
        name: 'Excluir',
        action: (val: any) => {
          http.deleteById(CATEGORIA, val.id).subscribe(resp => {
            this.table.find()
          })
        },
        icon: 'bi bi-trash3'
      }
    })
  }

  ngOnInit(): void {
  }

}
