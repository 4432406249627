import { Routes } from '@angular/router';
import {HomeComponent} from "./home/home/home.component";
import {
  ServicosAgendaveisMainComponent
} from "./servicos-agendaveis/servicos-agendaveis-main/servicos-agendaveis-main.component";
import {
  ServicosAgendaveisInsertEditComponent
} from "./servicos-agendaveis/servicos-agendaveis-insert-edit/servicos-agendaveis-insert-edit.component";
import {
  FoodEsteiraPedidosMainComponent
} from "./food/food-esteira-pedidos/food-esteira-pedidos-main/food-esteira-pedidos-main.component";
import {
  FormaPagamentoMainComponent
} from "./cadastros/forma-pagamento/forma-pagamento-main/forma-pagamento-main.component";
import {EmpresaMainComponent} from "./cadastros/empresa/empresa-main/empresa-main.component";
import {EmpresaInsertEditComponent} from "./cadastros/empresa/empresa-insert-edit/empresa-insert-edit.component";
import {ClientesMainComponent} from "./cadastros/clientes/clientes-main/clientes-main.component";
import {ClientesInsertEditComponent} from "./cadastros/clientes/clientes-insert-edit/clientes-insert-edit.component";
import {ColaboradorMainComponent} from "./cadastros/colaborador/colaborador-main/colaborador-main.component";
import {
  ColaboradorInsertEditComponent
} from "./cadastros/colaborador/colaborador-insert-edit/colaborador-insert-edit.component";
import {EntregadorMainComponent} from "./cadastros/entregador/entregador-main/entregador-main.component";
import {CategoriaMainComponent} from "./cadastros/categoria/categoria-main/categoria-main.component";
import {ProdutosMainComponent} from "./cadastros/produto/produtos-main/produtos-main.component";
import {
  FoodProdutosInsertEditComponent
} from "./cadastros/produto/food-produtos-insert-edit/food-produtos-insert-edit.component";

export const PrivateRouters: Routes = [
  { path: 'home', component: HomeComponent },

  { path: 'food/esteira-pedidos', component: FoodEsteiraPedidosMainComponent },

  { path: 'cadastros/produtos', component: ProdutosMainComponent },
  {
    path: 'cadastros/produtos',
    children: [
      { path: ':id', component: FoodProdutosInsertEditComponent }
    ]
  },

  { path: 'cadastros/empresa', component: EmpresaMainComponent },
  {
    path: 'cadastros/empresa',
    children: [
      { path: ':id', component: EmpresaInsertEditComponent }
    ]
  },

  { path: 'cadastros/colaboradores', component: ColaboradorMainComponent },
  {
    path: 'cadastros/colaboradores',
    children: [
      { path: ':id', component: ColaboradorInsertEditComponent }
    ]
  },


  { path: 'cadastros/servicos-agendaveis', component: ServicosAgendaveisMainComponent },
  {
    path: 'cadastros/servicos-agendaveis',
    children: [
      { path: ':id', component: ServicosAgendaveisInsertEditComponent }
    ]
  },

  { path: 'cadastros/clientes', component: ClientesMainComponent },
  {
    path: 'cadastros/clientes',
    children: [
      { path: ':id', component: ClientesInsertEditComponent }
    ]
  },

  { path: 'cadastros/categorias', component: CategoriaMainComponent },

  { path: 'cadastros/entregador', component: EntregadorMainComponent },

  { path: 'cadastros/forma-pagamento', component: FormaPagamentoMainComponent },

];
