import {AbstractEntity2, CrudEntity} from "@datagrupo/dg-crud";
import {DynamicColumn, DynamicTableEntity} from "@datagrupo/dg-ng-util";
import {environment} from "../../../../../environments/environment";
import {EMPRESA} from "../../../../core/endpoints";


@CrudEntity({
  api: {
    path: environment.apiUrl,
    context: EMPRESA
  }
})
@DynamicTableEntity({
  api: {
    path: environment.apiUrl,
    context: EMPRESA
  }
})
export class EmpresaEntity extends AbstractEntity2 {
  constructor(
    id?: string,
    nome?: string,
    link?: string,
  ) {
    super();
    this.id = id;
    this.nome = nome;
    this.link = link;
  }

  @DynamicColumn({headerName: 'Nome'})
  nome: string | undefined;

  @DynamicColumn({headerName: 'Link'})
  link: string | undefined;
}
