import { Component, OnInit } from '@angular/core';
import {CdkDynamicTable, CdkDynamicTableService} from "@datagrupo/dg-ng-util";
import {FoodProdutoEntity} from "../../../../../entitys/food-produto.entity";

@Component({
  selector: 'app-produtos-main',
  templateUrl: './produtos-main.component.html',
  styleUrls: ['./produtos-main.component.scss']
})
export class ProdutosMainComponent implements OnInit {

  public table: CdkDynamicTable.tableClass;

  constructor(private dynamicTable: CdkDynamicTableService) {
    this.table = dynamicTable.createByEntity(new FoodProdutoEntity())
  }

  ngOnInit(): void {
  }

}
