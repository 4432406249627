import {AbstractEntity2, CrudEntity} from "@datagrupo/dg-crud";
import {DynamicTableEntity, DynamicColumn} from "@datagrupo/dg-ng-util";
import {FoodProdutoComplementosEntity} from "./food-produto-complementos.entity";
import {environment} from "../../environments/environment";
import {FOOD_PRODUTO} from "../core/endpoints";
import {FoodCategoriaEntity} from "./food-categoria.entity";
import {EstabelecimentoEntity} from "../pages/private/estabelecimento/estabelecimento.entity";
import {defaultActions} from "../core/config/dg-ng-util/config-local-dg-table";


@CrudEntity({
  api: {
    path: environment.apiUrl,
    context: FOOD_PRODUTO
  },
})
@DynamicTableEntity({
  api: {
    path: environment.apiUrl,
    context: FOOD_PRODUTO
  },
  filters: {
    group: 'produtos'
  },
  actions: {
    editar: defaultActions.editar(row => ['private', 'cadastros', 'produtos', row?.id]),
    excluir: defaultActions.excluir((row) => ({
      id: row.id,
      callback: 'produtos',
      url: FOOD_PRODUTO
    }))
  },
})
export class FoodProdutoEntity extends AbstractEntity2 {

  constructor(
    nome?: string,
    valor?: string,
    imageUrl?: string,
    categoria?: FoodCategoriaEntity,
    complementos?: FoodProdutoComplementosEntity[],
    empresa?: Partial<EstabelecimentoEntity>
  ) {
    super();
    this.nome = nome;
    this.valor = valor;
    this.categoria = categoria;
    this.empresa = empresa;
    this.imageUrl = imageUrl;
    this.complementos = Array.isArray(complementos) ? complementos : [];
  }

  @DynamicColumn({ headerName: 'nome' })
  nome: string | undefined;

  @DynamicColumn({ headerName: 'valor', resource: val => 'R$ '+ val })
  valor: string | undefined;

  @DynamicColumn({ headerName: 'categorias', resource: val => val?.nome || '--' })
  categoria: FoodCategoriaEntity | undefined;

  imageUrl: string | undefined;
  complementos: FoodProdutoComplementosEntity[] = []
  empresa: Partial<EstabelecimentoEntity> | undefined
}
