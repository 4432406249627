import { Component, OnInit } from '@angular/core';
import {AbstractInsertEdit2, InsertEditConfig2} from "@datagrupo/dg-crud";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {ServicosAgendaveisEntity} from "../servicos-agendaveis.entity";
import {HttpService} from "../../../../services/http-service/http.service";
import {CATEGORIA, COLABORADOR} from "../../../../core/endpoints";
import {ColaboradorEntity} from "../../cadastros/colaborador/colaborador.entity";

@Component({
  selector: 'app-servicos-agendaveis-insert-edit',
  templateUrl: './servicos-agendaveis-insert-edit.component.html',
  styleUrls: ['./servicos-agendaveis-insert-edit.component.scss']
})
export class ServicosAgendaveisInsertEditComponent extends AbstractInsertEdit2<ServicosAgendaveisEntity> implements OnInit {

  rootEntity = new ServicosAgendaveisEntity();
  listCategorias: /*CategoriaEntity*/any[] = [];
  listColaboradores: ColaboradorEntity[] = [];

  form = new FormGroup({
    nome: new FormControl('a', [Validators.required]),
    tempo: new FormControl('a', [Validators.required]),
    colaboradores: new FormControl('a', [Validators.required]),
    valor: new FormControl('a', [Validators.required]),
    comissao: new FormControl('a', [Validators.required]),
    categorias: new FormControl([], [Validators.required]),
  })

  constructor(public config: InsertEditConfig2, private service: HttpService) {
    super(config)
    service.get(CATEGORIA).subscribe(resp => {
      this.listCategorias = resp.data;
    })
    service.get(COLABORADOR).subscribe(resp => {
      this.listColaboradores = resp.data;
    })
  }

  override ngOnInit(): void {
    super.ngOnInit();
  }

  override afterFetchEntity(entityData: any) {
    this.form.patchValue({
      ...entityData,
      categorias: (entityData.categorias || []).map((item: /*CategoriaEntity*/any) => item.id),
      colaboradores: (entityData.colaboradores || []).map((item: ColaboradorEntity) => item.id)
    })
  }

  override beforeSaveEntity(): boolean {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return false;
    }

    const form = this.form.value;

    this.entity = {
      ...this.entity,
      ...form,
      categorias: (form.categorias || []).map((id: number | string) => {
        return { id }
      }),
      colaboradores: (form.colaboradores || []).map((id: number | string) => {
        return { id }
      }),
    }

    return true;
  }

}
