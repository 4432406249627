import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {DgModalComponent} from "@datagrupo/dg-ng-util";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {HttpService} from "../../../../../../services/http-service/http.service";
import {EntregadorEntity} from "../../../entregador/entregador.entity";
import {ENTREGADOR, FOOD_CATEGORIA} from "../../../../../../core/endpoints";
import {Observable} from "rxjs";
import Swal from "sweetalert2";

@Component({
  selector: 'modal-categoria-insert-edit',
  templateUrl: './modal-categoria-insert-edit.component.html',
  styleUrls: ['./modal-categoria-insert-edit.component.scss']
})
export class ModalCategoriaInsertEditComponent implements OnInit {

  @ViewChild('modal') modal!: DgModalComponent;
  @Output('afterSave') afterSave = new EventEmitter<void>()

  form = new FormGroup({
    id: new FormControl(''),
    nome: new FormControl('', [Validators.required]),
  })

  constructor(private http: HttpService) { }

  ngOnInit(): void {
  }

  open(categoria?: EntregadorEntity) {
    if (categoria) {
      this.http.get(FOOD_CATEGORIA+'/'+categoria.id).subscribe(resp => {
        this.form.patchValue(resp.data)
        this.modal.open()
      })
    } else {
      this.modal.open()
    }
  }

  close() {
    this.modal.close()
  }

  clear() {
    this.form.reset('')
  }

  save() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return
    }

    const form = this.form.value;
    let request: Observable<any>

    if (form.id) {
      request = this.http.put(FOOD_CATEGORIA, form)
    } else {
      delete form.id;
      request = this.http.post(FOOD_CATEGORIA, form)
    }

    request.subscribe(resp => {
      this.afterSave.emit()
      Swal.fire({
        icon: "success",
        title: form.id ? 'Atualizações salvas' : 'Entregador cadastrado'
      }).then()
      this.close()
    })
  }
}
