import {AbstractEntity2, CrudEntity} from "@datagrupo/dg-crud";
import {DynamicTableEntity, DynamicColumn} from "@datagrupo/dg-ng-util";
import {environment} from "../../../../../environments/environment";
import {CLIENTE} from "../../../../core/endpoints";

@CrudEntity({
  api: {
    path: environment.apiUrl,
    context: CLIENTE
  }
})
@DynamicTableEntity({
  api: {
    path: environment.apiUrl,
    context: CLIENTE
  }
})
export class ClienteEntity extends AbstractEntity2 {

  constructor(
    nome?: string,
    email?: string,
    telefone?: string,
  ) {
    super();
    this.nome = nome;
    this.email = email;
    this.telefone = telefone;
  }

  @DynamicColumn({ headerName: 'nome' })
  nome: string | undefined;
  @DynamicColumn({ headerName: 'email' })
  email: string | undefined;
  @DynamicColumn({ headerName: 'Telefone' })
  telefone: string | undefined;
}
