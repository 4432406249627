import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {HttpService} from "../../../../../services/http-service/http.service";
import {EMPRESA_EXPEDIENTE_STATUS} from "../../../../../core/endpoints";
import {WebsocketService} from "../../../../../services/websocket/websocket.service";
import {StatusPedidoEnum, statusPedidoType} from "../../../../../enums/status-pedido.enum";
import {SessionService} from "../../../../../services/session/session.service";
import {CdkDragDrop, moveItemInArray, transferArrayItem} from "@angular/cdk/drag-drop";
import {FoodEsteiraPedidoService} from "../service/food-esteira-pedido.service";
import Swal from "sweetalert2";
import {replaceEndpointParams} from "../../../../../helpers/endpoints-helpers";

export enum labelTables {
  'EM_ANALISE' = 'listPedidosEmAnalise',
  'PRODUCAO' = 'listPedidosProducao',
  'PRONTO' = 'listPedidosPronto',
  'CAMINHO' = 'listPedidosACaminho',
  'ENTREGUE' = 'listPedidosEntrege',
}

@Component({
  selector: 'app-food-esteira-pedidos-main',
  templateUrl: './food-esteira-pedidos-main.component.html',
  styleUrls: ['./food-esteira-pedidos-main.component.scss']
})
export class FoodEsteiraPedidosMainComponent implements OnInit, OnDestroy {
  constructor(
    private http: HttpService,
    private socketService: WebsocketService,
    public session: SessionService,
    public esteiraService: FoodEsteiraPedidoService
  ) {
    if (this.session.expedienteAberto) {
      this.initEsteira()
    }
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.socketService.socket.off('esteira-produtos-' + this.session.user.estabalecimento)
  }

  @HostListener('window:init-esteira-pedido')
  initEsteira() {
    this.esteiraService.getTables('EM_ANALISE')
    this.esteiraService.getTables('PRODUCAO')
    this.esteiraService.getTables('PRONTO')
    this.esteiraService.getTables('PREPARO_ENTREGA')
    this.esteiraService.getTables('ENTREGA_ANDAMENTO')
    this.esteiraService.getTables('ENTREGUE')
    this.socketService.socket.on(
      'esteira-produtos-' + this.session.user.estabalecimento,
      (resp: { status: statusPedidoType[], novoProduto: boolean }) => {
        if (!Array.isArray(resp.status)) return;
        resp.status.map(table => this.esteiraService.getTables(table))
      })
  }

  @HostListener('window:update-table-esteira', ['$event'])
  eventUpdateTableEsteira(ev: CustomEvent<statusPedidoType>) {
    const status = ev.detail;
    this.esteiraService.getTables(status)
  }

  drop(event: CdkDragDrop<any[]>) {
    if (event.previousContainer === event.container) {
      // moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      const result = event.previousContainer.data.filter((item) => !!item['selectedItem']);
      const peviousStatus = <statusPedidoType>event.previousContainer.id.split('-')[1];
      const nextStatus = <statusPedidoType>event.container.id.split('-')[1];

      if (result.length > 0) {
        this.changeStatusPedido(result.map(item => item.id), peviousStatus, nextStatus);
      } else {
        this.changeStatusPedido([event.previousContainer.data[event.previousIndex].id], peviousStatus, nextStatus);
      }
    }
  }

  changeStatusPedido(pedidoId: string[], peviousStatus: statusPedidoType, nextStatus: statusPedidoType) {
    // const clearStatus = (val: string) => val.replace('_', ' ')
    // Swal.fire({
    //   icon: 'question',
    //   title: 'Confirmar mudança',
    //   text: `Confirme que está mudando ${pedidoId.length == 1 ? '1 pedido' : pedidoId.length + ' pedidos'}`+
    //     ` de "${clearStatus(peviousStatus)}" para "${clearStatus(nextStatus)}"`
    // }).then(confirm => {
    //   if (confirm.isConfirmed) {
        this.esteiraService.changeStatusArray(pedidoId, peviousStatus, nextStatus);
      // }
    // })
  }

  iniciarExpediente() {
    this.http.put(replaceEndpointParams(EMPRESA_EXPEDIENTE_STATUS, 'INICIAR'), {}).subscribe(
      () => {
        Swal.fire({
          icon: 'success',
          title: 'Expediente iniciado'
        }).then()
      },
      error => {
        Swal.fire({
          icon: 'error',
          title: error.error.message
        }).then()
      }
    )
  }

  finalizarExpediente() {
    this.http.put(replaceEndpointParams(EMPRESA_EXPEDIENTE_STATUS, 'Finalizar'), {}).subscribe(
      () => {
        Swal.fire({
          icon: 'success',
          title: 'Expediente finalizado'
        }).then()
      }
    )
  }

  formateDate(dateString?: string) {
    if (!dateString) return '--';
    return new Date(dateString).toLocaleString('pt-br');
  }
}
