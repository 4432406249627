import {Component, OnInit, ViewChild} from '@angular/core';
import {DgModalComponent} from "@datagrupo/dg-ng-util";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {FoodEsteiraPedidoService} from "../../service/food-esteira-pedido.service";
import {CdkDragDrop, moveItemInArray, transferArrayItem} from "@angular/cdk/drag-drop";
import {VendaEntity} from "../../../venda.entity";
import Swal from "sweetalert2";
import {find, Observable} from "rxjs";
import {HttpService} from "../../../../../../services/http-service/http.service";
import {ESTEIRA_PEDIDOS_ENTREGA, EXPEDIENTE_ENTREGADOR} from "../../../../../../core/endpoints";
import {VendaEntregaEntity} from "../../../../../../entitys/venda-entrega.entity";
import {EntregadorEntity} from "../../../../cadastros/entregador/entregador.entity";

declare type itemEntrega = { select: boolean, venda: VendaEntity }

@Component({
  selector: 'modal-esteira-entrega',
  templateUrl: './modal-esteira-entrega.component.html',
  styleUrls: ['./modal-esteira-entrega.component.scss']
})
export class ModalEsteiraEntregaComponent implements OnInit {

  @ViewChild('modal') modal!: DgModalComponent;

  public entity: VendaEntregaEntity = new VendaEntregaEntity()

  listPedidosProntos: itemEntrega[] = []
  listPedidosSelecionados: itemEntrega[] = []

  listEntregadores: EntregadorEntity[] = []

  form = new FormGroup({
    id: new FormControl(''),
    entregador: new FormControl('', [Validators.required])
  })

  constructor(public esteiraService: FoodEsteiraPedidoService, private http: HttpService) { }

  ngOnInit(): void {
  }

  open(data?: VendaEntregaEntity) {
    this.http.get(EXPEDIENTE_ENTREGADOR).subscribe(resp => {
      this.listEntregadores = resp.data;

      const listPedidosProntos = <any[]>JSON.parse(JSON.stringify(this.esteiraService.list_status_pedido.Pronto));

      this.listPedidosProntos = listPedidosProntos.map(venda => ({select: false, venda}))

      if (!!data) {
        this.entity = data;
        this.form.patchValue({
          id: data.id,
          entregador: data?.entregador?.id || ''
        })

        if (!!data.entregador) {
          // @ts-ignore
          this.listEntregadores.push(data.entregador)
        }

        this.listPedidosSelecionados = (data?.vendas || []).map(venda => ({ select: false, venda }));
      }

      this.modal.open()
    })
  }

  close() {
    this.modal.close()
  }

  clear() {
    this.form.reset({
      id: '',
      entregador: '',
    });
    this.listPedidosProntos = [];
    this.listPedidosSelecionados = [];
    this.entity = new VendaEntregaEntity();
  }

  drop(event: CdkDragDrop<itemEntrega[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
    }
  }

  save() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    if (this.listPedidosSelecionados.length <= 0) {
      Swal.fire({
        icon: 'warning',
        title: 'Nenhum pedido selecionado',
        text: 'Selecione ao menos um produto para entrega'
      }).then()
      return;
    }

    Swal.fire({
      icon: 'question',
      title: 'Iniciar viagem?',
      text: 'Clique em "Finalizar depois" para continuar adicionando pedidos a essa viagem',
      showDenyButton: true,
      showCancelButton: true,
      denyButtonText: 'Finalizar depois',
      cancelButtonText: 'Voltar',
      confirmButtonText: 'Iniciar viagem'
    }).then(confirm => {
      if (confirm.isDenied || confirm.isConfirmed) {
        this.entity.status = confirm.isDenied ? 'PREPARO_ENTREGA' : 'ENTREGA_ANDAMENTO';
        this._save();
      }
    })
  }

  private _save() {
    const {id, entregador} = this.form.value;

    const entity = {
      ...this.entity,
      id,
      entregador: { id: entregador },
      vendas: this.listPedidosSelecionados.map(item => item.venda)
    }

    const {dynamicTable, ...resultEntity} = entity;

    let request: Observable<any>

    if (!resultEntity.id) {
      const {id, ...restResult} = resultEntity;
      request = this.http.post(ESTEIRA_PEDIDOS_ENTREGA, restResult)
    } else {
      request = this.http.put(ESTEIRA_PEDIDOS_ENTREGA, resultEntity)
    }

    request.subscribe(resp => {
      this.close()
    })
  }

  passarTodos() {
    this.listPedidosSelecionados = [
      ...this.listPedidosSelecionados,
      ...this.listPedidosProntos.map(item => {
        item.select = false;
        return item
      })
    ]
    this.listPedidosProntos = [];
  }

  passarSelecionados() {
    let listPedidosProntos = <itemEntrega[]>JSON.parse(JSON.stringify(this.listPedidosProntos))
    listPedidosProntos = listPedidosProntos
      .filter(item => item.select)
      .map(item => {
        item.select = false;
        return item
      })

    this.listPedidosSelecionados = [
      ...this.listPedidosSelecionados,
      ...listPedidosProntos
    ]

    this.listPedidosProntos = [...this.listPedidosProntos.filter(item => !item.select)];
  }

  voltarTodos() {
    this.listPedidosProntos = [
      ...this.listPedidosProntos,
      ...this.listPedidosSelecionados.map(item => {
        item.select = false;
        return item
      })
    ]
    this.listPedidosSelecionados = [];
  }

  voltarSelecionados() {
    let listPedidosSelecionados = <itemEntrega[]>JSON.parse(JSON.stringify(this.listPedidosSelecionados))
    listPedidosSelecionados = listPedidosSelecionados
      .filter(item => item.select)
      .map(item => {
        item.select = false;
        return item
      })

    this.listPedidosProntos = [
      ...this.listPedidosProntos,
      ...listPedidosSelecionados
    ]

    this.listPedidosSelecionados = [...this.listPedidosSelecionados.filter(item => !item.select)];
  }

}
