import {VendaEntity} from "../pages/private/food/venda.entity";
import {AbstractEntity2} from "@datagrupo/dg-crud";
import {statusPedidoEntregaType} from "../enums/status-pedido.enum";
import {EntregadorEntity} from "../pages/private/cadastros/entregador/entregador.entity";

export class VendaEntregaEntity extends AbstractEntity2 {
  constructor(
    id?: string,
    entregador?: Partial<EntregadorEntity>,
    vendas?: VendaEntity[],
    status?: statusPedidoEntregaType,
  ) {
    super();
    this.id = id
    this.entregador = entregador
    this.vendas = Array.isArray(vendas) ? vendas : []
    this.status = status
  }

  entregador: Partial<EntregadorEntity> | undefined;
  vendas: VendaEntity[] = [];
  status: statusPedidoEntregaType | undefined
}
