<dg-modal
  #modal
  id="modal-esteira-pedido"
  size="md"
  title="Pedido N° 1235"
  (afterClose)="clear()"
>
  <div modal-body class="container-fluid">
    <div class="row">
      <div class="col-4">
        <label class="form-label fs-10">Cliente</label>
        <p class="text-muted">{{entity?.cliente?.nome}}</p>
      </div>
      <div class="col-4">
        <label class="form-label fs-10">Whatsapp</label>
        <p class="text-muted">{{entity?.cliente?.telefone}}</p>
      </div>
    </div>
    <div class="row">
      <div class="col-3 fs-10">
        <label class="form-label fs-10">Total de itens</label>
        <p class="text-muted">{{(entity?.produtos || []).length}}</p>
      </div>
      <div class="col-3 fs-10">
        <label class="form-label">Total do pedido</label>
        <p class="text-muted">R$: {{entity?.total}}</p>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <label class="form-label">Pedido</label>
      </div>
      <div class="col-12">
        <div class="accordion" id="accordionExample">
          <div class="accordion-item" *ngFor="let produto of (entity?.produtos || [])">
            <h2 class="accordion-header" id="headingOne">
              <button
                class="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                [attr.data-bs-target]="'#collapseOne-'+produto.id"
                [attr.aria-expanded]="(produto.complementos || []).length > 0 ? 'true' : 'false'"
                [attr.aria-controls]="'collapseOne-'+produto.id"
                [class.collapsed]="(produto.complementos || []).length <= 0"
                [class.no-icon]="(produto.complementos || []).length <= 0"
              >
                <img
                  [src]="produto.produto?.imageUrl || 'assets/images/default/default-placeholder.png'"
                >
                {{produto?.nome}}
              </button>
            </h2>
            <div
              *ngIf="(produto.complementos || []).length > 0"
              [id]="'collapseOne-'+produto.id"
              class="accordion-collapse collapse"
              [class.show]="(produto.complementos || []).length > 0"
              aria-labelledby="headingOne"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                <ul class="list-group">
                  <li class="list-group-item" *ngFor="let complemento of (produto.complementos || [])">
                    <h6>{{complemento?.nome}}</h6>

                    <ul class="list-group">
                      <li class="list-group-item" *ngFor="let item of (complemento?.items || [])">
                        {{item?.nome}}
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>

  </div>
  <div modal-footer>
    <button *ngIf="entity?.status == 'EM_ANALISE'" (click)="changeStatus('PRODUCAO')" class="btn btn-success">Aceitar
      pedido
    </button>
    <button *ngIf="entity?.status == 'PRODUCAO'" (click)="changeStatus('PRONTO')" class="btn btn-success">Pedido
      pronto
    </button>
  </div>
</dg-modal>
