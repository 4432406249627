import {CdkDgTable, setConfigDgTable} from "@datagrupo/dg-ng-util";

export declare type generateActionRemove = {
  id: string | number | (string | number)[] | undefined,
  url: string,
  callback: string | (() => void),
  messageBefore?: { title: string, text: string },
  messageAfter?: { title: string, text: string }
}

export const configLocalDgTable: setConfigDgTable = {
  // typeShowActions: 'inline-action',
  headerNameActions: '',
  typeShowActions: 'inline-action'
}

export const defaultActions: {
  editar: (router: (row: any) => (string | number)[]) => CdkDgTable.actions,
  excluir: (detail: (row: any) => generateActionRemove) => CdkDgTable.actions
} = {
  editar: router => {
    return {
      name: 'Editar',
      icon: 'bi bi-pencil-fill',
      dbClick: true,
      action: row => {
        window.dispatchEvent(
          new CustomEvent('default-actions-dg-tables-router',
            {detail: router(row)}))
      }
    };
  },
  excluir: detail => {
    return {
      name: 'Excluir',
      icon: 'bi bi-trash3',
      action: row => {
        window.dispatchEvent(new CustomEvent('default-actions-dg-tables-remove',
          {
            detail: detail(row)
          }
        ))
      }
    }
  }
}
