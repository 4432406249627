<div class="card card-entrega-pedido-esteira card-content-status-pedido-esteira" [class.selected]="!!data['selectedItem']">
  <div
    class="card-header"
    cdkDrag
  >
    <div class="row">
      <div class="col-12 d-inline-flex justify-content-between">
        <span>{{data?.entregador?.nome || '--'}}</span>
        <span matTooltip="Total de entregas">{{(data.vendas || []).length}}</span>
      </div>
    </div>
  </div>
  <div class="card-body">
    <ul class="list-group">
      <li class="list-group-item" *ngFor="let item of (data.vendas || [])">
        <span>{{item?.cliente?.nome}}</span>
      </li>
    </ul>
  </div>
  <div class="card-footer d-inline-flex justify-content-between">
    <button *ngIf="data?.status == 'PREPARO_ENTREGA'" [disabled]="data?.selectedItem" (click)="open.emit()" class="btn btn-info btn-sm">Abrir</button>
    <button *ngIf="data?.status == 'ENTREGA_ANDAMENTO'" [disabled]="data?.selectedItem" (click)="modalEntregaAndamento.open(data)" class="btn btn-info btn-sm">Abrir</button>
  </div>
</div>



<modal-esteira-entrega-andamento #modalEntregaAndamento>
</modal-esteira-entrega-andamento>
