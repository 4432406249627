import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {AjusteDecimalDirective} from "./input-decimal-number";



@NgModule({
  declarations: [
    AjusteDecimalDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
    AjusteDecimalDirective
  ]
})
export class DirectivesModule { }
